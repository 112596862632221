.selector-width {
    .ui-slider__input-wrapper {
        width: 100%;
    }
}

.selector-width {
    width: 77%;
}

.position-selector  {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.distance-position{
    text-align: end;
}

.ui-flex.ap.kx{
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width:990px) {
    .ui-dialog.location-block {
        width: 70%;
    }
}

@media screen and (max-width:720px) {
    .ui-dialog.location-block {
        width: 80%;
    }
    .selector-width {
        width: 73%;
    }
    
}

@media screen and (max-width:540px) {
    .ui-dialog.location-block {
        width: 92%;
    }
    .selector-width {
        width: 68%;
    }
}

@media screen and (max-width:415px) {

    .selector-width {
        width: 57%;
    }
}


@import './../../assets/styles/colors.scss';

.side-header {
    padding: 2.13rem 2.13rem 0 2.13rem;
}

.wrapper-header {
    display: flex;
    align-items: flex-end;

    p {
        margin-right: 10px;
    }

    a {
        cursor: pointer;
    }

    .title {
        font-family: 'Segoe-Semi-Bold';
        font-size: 20px;
    }
}

.panel-side-bar {
    .support-dropdown-menu {
        display: none;
        margin-left: auto;
    }    
}

.support-bar {
    flex: 1 1 auto;
    overflow: auto;
    padding-left: 2.13rem;
    padding-right: 2.13rem;
}

.support-search {
    .search-fragment {
        padding: 15px 0 15px 0;

        header {
            button {
                padding: 0;
                width: auto;
                margin-right: 15px;
            }

            font-family: 'Segoe-Semi-Bold';
            font-size: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.active {
                font-size: 24px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:not(.expanded) {
            .support-content {
                display: none !important;
            }
        }

        &.expanded {
            .support-content {
                display: block;
            }

        }

        .ui-checkbox__indicator {
            width: 17px;
            height: 17px;

            &:checked {
                box-shadow: 0 0 0 0.133rem #ffff00;
                background-color: red;
            }
        }

        .support-content {
            margin-top: 20px;
            ul {
                li {
                    display: flex;
                }
            }

            label {
                display: flex;
                .separator {
                    margin: 0 10px;
                }
            }

            .radio-row {
                margin-bottom: 15px;

                .content-radio-button {
                    margin-right: 10px;

                    p {
                        width: 50px;
                    }
                }

            }

            .checkbox-row {
                margin-bottom: 10px;

                .support-checkbox {
                    margin-right: 50px;
                }
            }

            .duration-time {
                color: $purple-color;
                font-size: 16px;
            }
        }
    }
}

.expanded-panel{
    // width: 39%;
    position: absolute;
    z-index: 999;
    height: 87%;
    top: 75px;
    left: 26px;
    background-color: rgb(45, 44, 44);
    padding: 40px;
}

@media screen and (min-height: 1080px) and (max-height: 1450px){
    .panel-side-bar{
        max-height: 92vh;
    }
}

@media screen and (max-width: 794px) {
    .side-header {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .support-dropdown-menu {
        display: block !important;
    }

    .side-bar{
        .support-search{
            display: none;
            &.active {
                display: block;
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {

    .support-content ul li{
        flex-wrap: wrap;
        &.date-time-support .support-datepicker{
            margin-right: 0;
            margin-left: auto;
            margin-bottom: 0;
        }
        &.date-time-support .support-timepicker{
            margin-left: 66px;
            width: 140px;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }
}
@import 'src/assets/styles/colors';

.horizontalSlider {
    width: 100%;
    height: 10px;

    // The global keyword is needed here because the slider component constructs the subclass names by appending '-0' and '-1' 
    // (like 'sliderTrack-1' and 'sliderButtons-1'). Without the global keyword Sass will generate unique names for every CSS class,
    // which breaks this name based matching mechanism.
    :global {           
        .sliderTrack {
            margin-top: 0; 
            top: 0;
            bottom: 0;                                   
            background: #B3B4B4;
            border-radius: 999px;
            &.sliderTrack-1 {
                background:  #EBF1F4;                                        
            }
        }
   
        .sliderButtons {
            text-align: center;
            color: #fff;
            border-radius: 50%;
            cursor: grab;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            position: relative;
            margin-top: -2.5px;
            height: 15px;
            width: 15px;    
            // Remove the focus selection box around the slider thumb.
            &:focus {
                outline: none;                                        
            }
            // Increase the clickable area of the slider thumb (especially for touch devices) without increasing its size.
            &:after {
                content:'';
                position:absolute;
                top:-10px; bottom:-10px; 
                left:-10px; right:-10px; 
            }            
        }
    }

    .progressBarMarker {
        position: absolute;
        width: 5px;
        height: 25px;
        background-color: $markerColor;
        bottom: -8px;
        border-radius: 15px;
        transform: translateX(5px);

        &.darkProgressBarMarker {
            background-color: $darkMarkerColor;
        }
    }
}

$closedCaptionImageWhite: url('../../assets/images/icons/closed-captioning.svg');
$closedCaptionImageBlack: url('../../assets/images/icons/closed-captioning-black.svg');
$closedCaptionImagePurple: url('../../assets/images/icons/closed-captioning-purple.svg');
$closedCaptionImageYellow: url('../../assets/images/icons/closed-captioning-yellow.svg');

$downloadImageWhite: url('../../assets/images/icons/download-white.svg');
$downloadImageBlack: url('../../assets/images/icons/download-black.svg');
$downloadImagePurple: url('../../assets/images/icons/download-purple.svg');
$downloadImageYellow: url('../../assets/images/icons/download-yellow.svg');

.controls {
    align-items: center;

    .timeDisplay {
        margin: 0 15px;
        display: flex;
    }

    .progressBarContainer {
        align-items: center;
        margin: 0 10px;
        width: 100%;
    }

    .volumeSliderContainer {
        align-items: center;
        margin: 0 10px;

        .horizontalSlider {
            width: 140px;
            margin: 0 10px 0 0;
        }
    }

    .subtitleIcon {
        width: 20px;
        height: 17px;
        margin-top: 3px;
        margin-left: 15px;
        cursor: pointer;
        background-image: $closedCaptionImageWhite;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &:hover {
            background-image: $closedCaptionImagePurple;
        }

        &.lightTheme {
            background-image: $closedCaptionImageBlack;
        }

        &.subtitleActive {
            background-image: $closedCaptionImagePurple;
        }

        &.highContrastTheme {
            &:hover {
                background-image: $closedCaptionImageYellow;
            }

            &.subtitleActive {
                background-image: $closedCaptionImageYellow;
            }
        }
    }

    .downloadIcon {
        width: 20px;
        height: 20px;
        margin-left: 15px;
        cursor: pointer;
        // background-image: $downloadImageWhite;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &:hover {
            color: #939af5;
        }

        &.lightTheme {
            color: #000;
        }

        &.highContrastTheme {
            &:hover {
                color: #ffff00;
            }
        }
    }

    .spinner {
        width: 20px;
        height: 20px;
        margin-left: 15px;
        cursor: pointer;
    }

    .fullScreenIcon {
        margin-left: auto;
        margin-right: 4px;
    }
}

.tooltip {
    padding: 5px;
    border-radius: 3px; 
    
    .tooltipTitle {
        font-weight: bold;        
    } 

    .highlight {
        background-color: $highlightBackgroundColor;
        color: $highlightTextColor;
    }

    .darkHighlight {
        background-color: green;
        color: blue;
    }
}
.summarization-item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-inline: 32px;
    padding-block: 20px;
    margin: 10px;
    &-shadow {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-name {
            margin: 0;
            padding: 0;
            // font-size: 28px;
            font-weight: 600;
            text-transform: capitalize;

            &-chapter {
                font-size: 20px;
            }
        }

        &-button {
            all: unset;
            margin-left: 36px;
        }

        &-icon {
            width: 32px;
            height: 32px;
            color: #5b5fc7;
            border-radius: 6px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                background-color: rgba(91, 95, 199, 0.2);
            }
        }
    }

    &-chapter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;

        &-name {
            margin: 0;
            padding: 0;
            // font-size: 24px;
            font-weight: 600;
            text-transform: capitalize;

            &-list {
                margin-top: 24px;
            }
        }
    }

    &-separator {
        width: 100%;
        height: 2px;
        margin-top: 8px;
        background-color: #5b5fc7;
    }

    &-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        list-style: disc;
        padding-left: 32px;
    }

    &-text {
        margin-top: 8px;
        font-size: 16px;
    }
}

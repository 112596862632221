.support-buttons {
    padding: 1rem 2.13rem;
    display: flex;
    flex-wrap: wrap;
}

.support-dropdown-menu {
    display: none;
    margin-left: auto;
}

.tab-list {
    margin-top: 20px;
    width: 100%;
}

.peoples {
    display: flex;
    align-items: center;
    position: sticky;
    top: -12;
    margin-bottom: 20px;
    padding-bottom: 5px;
}

div.video-left-panel- {
    display: flex;
    align-items: center;
    padding: 1rem 2.13rem;
    padding-bottom: 20px;
    border-top: none;
    max-height: 74vh;
}

.media-menu {
    margin-left: auto;
}

@media (min-width: 0px) and (max-width: 1280px) {
    .support-dropdown-menu {
        display: block;
    }

    .tab-list {
        display: none;
        &.active {
            display: block;
        }
    }

    div.video-left-panel- {
        display: flex;
        align-items: center;
        padding: 1rem 2.13rem;
        padding-bottom: 0;
        border-top: none;
        max-height: 74vh;
    }
    .media-menu {
        margin-left: 0;
    }
    .support-dropdown-menu {
        align-content: center;
    }
}

@media screen and (max-width: 500px) {
    .support-buttons {
        padding-left: 2.13rem;
        padding-right: 2.13rem;
    }
}

.sidebar-container {
    display: flex;
    position: relative;
    width: 100%;
}

.resize-handler {
    display: flex;
    position: absolute;
    top: 60px;
    right: -10px;
    width: 32px;
    height: 24px;
    padding-left: 8px;
    border-radius: 8px;
    cursor: col-resize;

    &-icon {
        width: 24px;
        height: 24px;
        color: white;
    }
}

@media (max-width: 1280px) {
    .resize-handler {
        display: none;
    }
}

.time-input{
    input{
        width: 100%;
    }
}


.fui-FluentProvider .fui-Listbox{
    height: 175px;
}

.date-time-support {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 15px;
    gap: 15px;
    .title {
        margin-right: 1rem;
        width: 50px;
    }
    .datepicker{
        width: 200px;
    }
}

.support-timepicker {
    position: relative;
    display: flex;
    align-items: center;
    width: 200px;
    border: 0.5px solid;
    border-radius: 3px;

    &-icon {
      position: absolute;
      right: 15%;
    }

    .r-input-group {
        height: auto;

        .r-input {
            border: 0px;
            height: auto;
            padding: 0;
            border-radius: 0;
            outline: none;
            margin-left: 10px;
            width: 75%;
            background: transparent;
        }

    }

    &.dark {
        .r-input-group {
            .r-input {
                color: #ffffff;
            }
        }
    }

    &.light {
      .r-input-group {
          .r-input {
              color: #000;
          }
      }
  }
}
.testpage {
    .transcriptionContainer
    {
        width: 30%;
        height: 500px;        
    }
    .button {
        display: block;
        margin: 10px;
        color: white;
    }
}
.audioContainer {
    width: 100%;
    min-width: 50%;
    position: relative;
  
    .playerControlsContainer {
        height: 60px;
        margin-top: 15px;
        width: 100%;
        min-width: 50%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        bottom: 0;
        background-color: transparent;
    }
}  

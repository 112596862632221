.wrapper {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.display--flex {
    display: flex;
}

.display--grid {
    display: grid;
    grid-template-columns: 385px 1fr;
    gap: 15px
}

.width-100 {
    width: 100%;
}

.flex--wrap {
    flex-wrap: wrap;
}

userLogin {
    grid-column-start: 4;
    grid-column-end: 5;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe-Regular';
    font-size: 14px;
    height: 100vh;
    overflow: hidden;
}

p {
    padding: 0;
    margin: 0;
}
.ui-provider {
    height: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.main-section {
    background-color: $background-color;
}

.mark {
    background-color: rgba($color: #991717, $alpha: 0.6);
}

.icon {
    background-repeat: no-repeat;
    cursor: pointer;
  }

// When this class is added to an element it is no longer selectable. 
// This is especially useful on touch devices where a long press automatically 
// starts the text-selection and often selects a random elements.
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.player::cue {
    font-size: 2.5vmin;
    line-height: 1 !important;
    // Set a transparent background color to override the default ugly black background in Chrome.
    background-color: rgba(45, 44, 44, 0.0) !important;
    text-shadow: black 2px 2px 5px;
}

// Work-around for the behavior of iOS Safari where the captions are getting rougly half the size when the
// video is in full-screen mode. The 'increasecaptionsize' class is added to the video element (by Javascript code)
// when the video is played in full-screen and is removed again when the full-screen mode is left.
.player.increasecaptionsize {
    &::cue{
        font-size: 5vmin !important;
    }
}


@media (max-width: 794px) {
    body {
        overflow: initial;
    }

    .wrapper{
        grid-template-columns: none;
        padding-left: 0;
        padding-right: 0;
    }
    .wrapper--top{
        .top-bar {
            .wrapper-input {
                userLogin {                
                    grid-column-start: 3;
                    grid-column-end: 4;
                }
            }
        }
    }
}


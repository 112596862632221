.main-content {
    .wrapper-header {
        padding-bottom: 10px;
        border-bottom: 1px solid #E1DFDD;
        align-items: center;
        width: 100%;
    }
}

.support-sorters-panel {
    position: relative;
    font-family: 'Segoe-Regular';
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;

    .sorter-title {
        width: 70px;
        padding: 8px 0px;
    }
}

@media (min-width: 320px) and (max-width: 500px) {
    .searchresult-panel-header {
        .wrapper-header {
            justify-content: center;
            gap: 10px;
        }
    }

    .support-sorters-panel {
        margin-left: unset;
    }
}
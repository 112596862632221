.support-datepicker {
    border: 1px solid;
    border-radius: 3px;
    margin-left: auto;
    display: flex;
    width: 140px;
    input{
        background-color: transparent;
        border: 0;
    }
    .ui-datepicker{
        display: flex;
        .ui-button {
            box-shadow: none;
            border: 0;
            height: auto;
        }
    }
}

.datepicker {
  width: 160px;
}
.pager-nav {
    padding: 10px 0;
}

.pager-list {
    display: flex;
    align-items: center;

    li {
        margin: 5px;

        .screen-reader-only {
            font-size: 0px;
        }
    }
}

.pager-page-item {
    padding: 8px 13px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.pager-page-item-active {
    padding: 8px 13px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.pager-page-item-disabled {
    display: inline;
    padding: 8px;
    cursor: not-allowed;
    opacity: 0.5;
    text-align: center;
    border-radius: 3px;
}

@media (max-width: 400px) {
    .pager-list li {
        margin: 0;
    }
}
.content-height {
    height: 100vh;

    .main {
        flex: 1 1 auto;
        overflow: auto;
        padding-left: 2.13rem;
        padding-right: 2.13rem;
    }
}

.support-login {
    height: 100%;
}

.login-container {
  display: flex;
}

.content-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    header {
        display: flex;
        width: 100%;

        .company-logo {
            width: 590px;
            height: 427px;
            background-image: url('../../assets/images/global/LogoCyberRetrieveRED-01_02.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        .company-text {
            font-size: 100px;
            font-family: 'Segoe-Semi-Bold';
            margin-left: -110px;
        }
    }

    footer {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: 'Segoe-Semi-Bold';
        font-size: 20px;

        p {
            margin: 0 10px;
        }

        .red-line {
            background-color: #E91E24;
            border-radius: 50px;
            width: 100%;
            height: 10px;
        }
    }
}

.login {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 27em;
    margin-left: auto;
    margin-right: 5em;
    margin-top: -135px;

    .title {
        font-size: 45px;
        width: 100%;
        margin-bottom: 32px;
    }

    .sub-text {
        font-size: 30px;
        width: 100%;
    }

    .login-button {
        margin: 40px 0 50px 0;
    }

    .small-text {
        width: 100%;
        font-size: 16px;
    }

    .error-text {
        width: 100%;
        font-size: 16px;
        color: red;
    }
}

@media only screen and (max-width: 1135px) {
    .control-center {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        header {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .company-logo {
                width: 270px;
                height: 290px;
            }

            .company-text {
                font-size: 70px;
                margin-left: 0;
                width: 100%;
                margin-top: 0;
                text-align: center;
            }
        }

        .login {
            margin-top: 40px;
            margin-left: unset;
            margin-right: unset;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .control-center {
        header {
            .company-text {
                font-size: 50px;
            }
        }
    }

    .login {
        width: 20em;

        .title {
            font-size: 30px;
        }

        .sub-text {
            font-size: 20px;
        }

        .small-text {
            font-size: 13px;
        }

        .login-button {
            margin: 40px 0 50px 0;
        }
    }
}
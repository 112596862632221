.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 2.13rem;

    .actions-wrapper {
        display: flex;
        justify-content: space-between;
    }
    h2 {
        margin-block: 5px;
    }
}
@media screen and (max-width: 768px) {
    .header {
        h2 {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 426px) {
    .header {
        padding: 8px 1rem;
        justify-content: right;

        h2 {
            display: none;
        }
    }
}

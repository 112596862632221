@import 'src/assets/styles/colors';

.horizontalSlider {
    width: 100%;
    height: 35px;
    .sliderButtons {
        margin-top: 12px;
        height: 30px;
        width: 30px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        cursor: grab;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    .sliderButtons-1 {
        z-index: 0  !important;
    } 
    .sliderTrack {
        margin-top: 20px;
        top: 0;
        bottom: 0;
        background: #F3F2F1;
        border-radius: 999px;
        &.sliderTrack-1 {
            background:  #DDD;
        }
    }
}
.wrapper--top {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.top-bar {
    .search-panel {
        flex: 1 1 auto;
        overflow: auto;
        padding-left: 2.133rem;
        padding-right: 2.133rem;
    }
}

.panel-side-bar {
    max-height: 87vh;
    overflow: auto;
}

@media screen and (max-width: 794px){
    .wrapper{
        .side-bar{
            width: 100%;
        }
    }
    .wrapper--top{
        grid-template-columns: none;
        padding-left: 0;
        padding-right: 0;
    }
    .panel-side-bar{
        max-height: none;
    }
}

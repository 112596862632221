.wrapper-input {
    height: 40px;
    display: grid;
    grid-gap: 10px;            
    grid-template-columns: minmax(200px, 1fr) 250px minmax(125px, 0.15fr) 35px;
    justify-items: stretch;
    align-items: center;
    align-content: center;
}

.search-field {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 2;
    .support-search-input {
        align-items: center;
        display: flex;            
        height: 36px;
    
        input {
            -webkit-appearance: none;
            height: 32px;
            font-size: 18px;
            border: none;
        }
    }
}

.searchin-dropdown {
    grid-column-start: 2;
    grid-column-end: 3;
    height: 32px;
    font-size: 18px;
    border: none;

    .ui-dropdown {
        position: absolute;
        width: 200px;
    }
}

.search-button {
    grid-column-start: 3;
    grid-column-end: 4;
}


@media screen and (max-width: 794px) {
    .wrapper-input {
        height: 90px;
        grid-template-columns: 250px auto 35px;
        .search-field {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    .searchin-dropdown {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        .ui-dropdown {
            width: 150px;
        }
    }
    .search-button {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

@media screen and (max-width: 360px) {
    .search-button {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
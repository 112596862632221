.face-button { 
    border-radius: 50px;
    border-width: 5px;
    border-color: transparent;
    border-style: solid;

 }
 .face-button-selected{
    border-color: #6264A7;
 }

 .face-button-image{
    width: 40px;
    height: 40px;
    -moz-border-radius: 50px; 
    -webkit-border-radius: 50px; 
    border-radius: 50px;
    background-position: 0px 0px;
    background-size: auto 100%;
    cursor: pointer;    
 }
.result-item {
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #E1DFDD;
}

.result-item-menu{
    margin-left: auto;
}

.thumbnail-support {
    position: relative;
    min-width: 190.75px;
    min-height: 109px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
    transition: opacity 1s ease-in-out;

    img {
        width: auto;
        height: 100%;
        max-width: 190.75px;
        max-height: 109px;
        position: absolute;
        left: 0;
        transition: opacity 1s ease-in-out;
        border-radius: 5px;

        &.overlay {
            opacity: 0;
            -webkit-filter: drop-shadow(5px 5px 5px rgb(77, 77, 77));
            filter: drop-shadow(5px 5px 5px rgb(77, 77, 77));
        }
    }

    &:hover {
        img {
            &.overlay {
                opacity: 0.9;
            }

            opacity: 0.8;
        }
    }
}
.text-result {
    h3 {
        margin-top: 0;
        display: flex;
        align-items: center;
    }

    .row {
        margin-bottom: 5px;

        .label {
            text-align: right;
            margin-right: 10px;
            max-width: 130px;
            width: 100%;
        }

        .text {
            &.text-bold {
                font-family: 'Segoe-Semi-Bold';
            }
        }
    }
}
.options {
    display: flex;

    .option-icon {
        width: 25px;
        height: 25px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 5px;

        &.bodyCam {
            background-image: url('../../assets/images/icons/axis_logo.png');
        }

        &.teams {
            background-image: url('../../assets/images/icons/teams.svg');
        }

        &.cybergate {
            background-image: url('../../assets/images/icons/cybergate-logo.png');
        }
    }
}

@media screen and (min-width: 795px) and (max-width: 1000px) {
    .result-item {
        flex-wrap: wrap;

        .text-result {
            width: 100%;
            margin-top: 10px;
        }
    }
}

@media screen and (min-width: 300px) and (max-width: 639px) {
    .result-item {
        flex-wrap: wrap;

        .text-result {
            width: 100%;
            margin-top: 10px;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .result-item {
        display: grid;
        grid-template-rows: 1fr;
        justify-content: center;
    }
    .thumbnail-support {
        min-width: 302px ;
        min-height: 169px ;
        margin-right: 0;

        img {
            max-width: 302px;
            max-height: 169px;
        }
    }
    .text-result{
        max-width: 300px;
    }
}
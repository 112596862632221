.main {
    text-align: center;
    padding-top: 50px;
}

.paddingTop {
    padding-top: 10px;
}

.paddingTopLarge {
    padding-top: 50px;
}

.details {
    padding-top: 25px;
}

.detailMessage {
    margin: auto;
    width: 50%;
    height: 500px;
    overflow: auto;    
}

.maintenanceImage {
    border-radius: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
}

@font-face {
	font-family: 'Segoe-Regular';
	src: url('../fonts/Segoe-UI.ttf') format('truetype');
	font-weight: 400;
}
@font-face {
	font-family: 'Segoe-Semi-Bold';
	src: url('../fonts/Segoe-UI-Semi-Bold.ttf') format('truetype');
	font-weight: 600;
}
.header-filters {
    .support-search-input {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .search-title {
            margin-right: 10px;
        }
    }
}

.support-arrows {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.content-arrows {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.transcriptionContent {
    height: 60vh;
    max-height: 60vh;
    .ui-chat__message {
        width: 100%;
        margin-right: 0em;
        max-width: 100% !important;
    }
}

.transcription-query-input {
    width: 100%;
    input {
        width: 100%;
    }
}

.videoContainer {
    position: relative;
    //min-height: 180px;
    width: 100%;
    display: grid;

    .loader {
        opacity: 1;
        position: absolute;
        height: 100px;
        width: 100px;
        left: 50%;
        top: 50%;    
        margin-left: -50px;
        margin-top: -50px;                
    }

    .spinner {
      display: flex;
      flex-direction: column;
    }

    .playIcon {
        opacity: 1;
        position: absolute;
        height: 100px;
        width: 177px;
        left: 50%;
        top: 40%;    
        margin-left: -88px;
        margin-top: -50px;              
    }

    .playerControlsContainer {            
        display: flex;
        height: 60px;
        margin-top: 10px;
        width: 100%;
        bottom: 0;
        align-items: center;
        flex-wrap: wrap;
        transition: opacity 0.8s, visibility 0.8s ease;

        // In full screen mode the controls are displayed as an overlay over the video,
        // but are hidden unless the 'showControls' class is added.
        &.fullscreen {
            bottom: 0;
            position: absolute;
            opacity: 0;
            visibility: 0;            
            &.showControls {
                opacity: 1;
                visibility: 1;
            }
        }       
    }
}
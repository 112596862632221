.launchPortal {
    display: grid;
    height: 100vh;
    margin: 0;
    place-items: center center;    
    align-items: center; 
    justify-content: center;

    .openButton {
        padding: 0 50px;
    }
}
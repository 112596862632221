.search-results-block{
    display: flex;
    flex-direction: column;
    height: 87vh;
    overflow: hidden;
} 

.searchresult-panel-header {
    padding: 2.13rem 3rem 0 2.13rem;
}

.searchresult-panel-body {
    margin-top: 20px;
    flex: 1 1 auto;
    overflow: auto;
    padding-left: 2.13rem;
    padding-right: 2.13rem;
}

.searchresult-panel-footer{
    padding-bottom: 0;
    padding-left: 2.13rem;
}

@media screen and (min-height: 1080px) and (max-height: 1450px) {
    .search-results-block {
        height: 92vh;
    }
}

@media screen and (max-width: 850px) {

    .searchresult-panel-footer{
        padding-left: 0;
    }
}

@media screen and (max-width: 794px) {
    .search-results-block {
        height: auto;
        overflow: initial;
    }
    .searchresult-panel-footer{
        position: sticky;
        bottom: 0px;
        background-color: #F3F2F1;
        justify-content: center;
        display: flex;
    }
}

@media (min-width: 320px) and (max-width: 450px) {
    .searchresult-panel-body {
        padding-left: 0px;
        padding-right: 0px;
    }
    .searchresult-panel-footer {
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
    }
}

@media screen and (max-height: 660px) {
    .search-results-block {
        height: 83vh;
    }
}

@media screen and (max-height: 575px) {
    .search-results-block {
        height: 80vh;
    }
}

.display--grid--video {
    gap: 15px;
    height: 100vh;
    width: 100%;
}

.support-main-content{
    height: 100vh;
    padding: 20px 15px;
}

.video-content {
    .main-content {
        width: 100%;
        min-width: 50%;
    }
    .panel{
        overflow: visible;
    }
    .panel-body {
        padding-top: 14px;
        padding-bottom: 10px;
        overflow: visible;
        padding-left: 2.133rem;
        padding-right: 2.133rem;
        flex: 1 1 auto;
        .error {
            color: red;
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 1280px) {

    .video-content {
        height: auto;
        &.wrapper{
            display: grid;
            grid-template-columns: none;
        }
        .side-bar-play {
            width: 100%;
        }

        .panel-body {
            padding: 0;
        }
    }
}

@media screen and (max-width: 500px) {
    .video-content{
        .side-bar-play {
            padding-bottom: 0px;
        }
        .playercontrols-timedisplay {
            display: none;
        }
    }
}

.player-container {
  display: flex;
  margin-top: 15px;
  gap: 15px;
}

@media (max-width: 1280px) {
  .player-container {
    flex-direction: column-reverse;
  }
}